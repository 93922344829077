import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Circle, useMapEvents, useMap } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import { TextField, Button, Box, InputAdornment, CircularProgress } from '@mui/material';
import { API_EXTERNAL_ENDPOINTS } from '../../utils/apiConfig';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';

export type Position = {
  lat: number,
  lng: number
};

interface MapComponentProps {
  position: Position | null;
  onLocationSelect?: (position: Position, cityName: string | null) => void; // Extend callback to include city name
  radius: number | null;
  mapHeight: string;
}

const MapComponent: React.FC<MapComponentProps> = ({ position: initialPosition, onLocationSelect, radius, mapHeight }) => {
  const [position, setPosition] = useState<Position | null>(initialPosition);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!position) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newPos = { lat: latitude, lng: longitude };
          setPosition(newPos);
          fetchLocationName(new L.LatLng(latitude, longitude));
        },
        () => {
          const defaultPos = { lat: 51.7666636, lng: 18.083333 }; // Default to Kalisz
          setPosition(defaultPos);
          fetchLocationName(new L.LatLng(51.7666636, 18.083333));
        }
      );
    }
  }, [position]);

  const fetchLocationName = async (coords: L.LatLng) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_EXTERNAL_ENDPOINTS.CITY_REVERSE_SEARCH}&lat=${coords.lat}&lon=${coords.lng}`);
      if (response.data && response.data.address) {
        const address = response.data.address;
        const city = address.city || address.town || address.village || address.hamlet;
        const cityName = city || `${t(Resource.MapComponent.UnknownLocation)}`;
        setInput(cityName);
        onLocationSelect && onLocationSelect({ lat: coords.lat, lng: coords.lng }, cityName);
      }
    } catch (error) {
      console.error(`${t(Resource.MapComponent.ErrorFetchingLocation)}`, error);
      setInput('');
      onLocationSelect && onLocationSelect({ lat: coords.lat, lng: coords.lng }, null);
    } finally {
      setLoading(false);
    }
  };

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        const newLocation = e.latlng;
        setPosition(newLocation);
        fetchLocationName(newLocation);
      }
    });

    return position ? (
      <>
        <Marker position={position} />
        <Circle center={position} radius={radius != null ? radius * 1000 : 50000} />
      </>
    ) : null;
  };

  const handleSearch = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.get(`${API_EXTERNAL_ENDPOINTS.CITY_SEARCH}${input}`);
      if (response.data && response.data.length > 0) {
        const { lat, lon } = response.data[0];
        const newLocation = { lat, lng: lon };
        setPosition(newLocation);
        fetchLocationName(new L.LatLng(lat, lon));
      }
    } finally {
      setLoading(false);
    }
  };

  const SetViewOnPosition = ({ position }: { position: Position }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(position, map.getZoom());
    }, [map, position]);
    return null;
  };

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          value={input}
          onChange={(e) => setInput(e.target.value)}
          label={t(Resource.MapComponent.InputPlaceholder)}
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            endAdornment: loading ? <InputAdornment position="end"><CircularProgress size={20}/></InputAdornment> : null
          }}
        />
        <Button variant="contained" onClick={handleSearch} sx={{ backgroundColor: "#0137fd", borderRadius: 0, whiteSpace: 'nowrap' }}>{t(Resource.Buttons.Search)}</Button>
      </Box>
      <MapContainer center={position || { lat: 51.7666636, lng: 18.083333 }} zoom={6} style={{ height: mapHeight, width: '100%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {position && <SetViewOnPosition position={position} />}
        <LocationMarker />
      </MapContainer>
    </Box>
  );
};

export default MapComponent;
