import React, { useState } from 'react';
import { Box, Button, MenuItem, Popover, Typography, Avatar } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';

// Import flag
import plFlag from '../../content/images/flags/poland.png';
import enFlag from '../../content/images/flags/united-kingdom.png';
// import uaFlag from '../../content/images/flags/ukraine.png';

const languages = [
  { code: 'pl', name: 'Polski', flag: plFlag },
  { code: 'en', name: 'English', flag: enFlag },
//   { code: 'uk', name: 'Українська', flag: uaFlag },
];

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode);
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none',
          color: 'black',
          fontWeight: 'bold',
        }}
      >
        <Avatar src={currentLanguage.flag} sx={{ width: 24, height: 24, mr: 1 }} />
        {currentLanguage.name}
        <ArrowDropDownIcon />
      </Button>

      {/* Popover z warunkiem otwierania */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box sx={{ p: 1 }}>
          {languages.map(lang => (
            <MenuItem
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                fontWeight: lang.code === i18n.language ? 'bold' : 'normal',
              }}
            >
              <Avatar src={lang.flag} sx={{ width: 24, height: 24 }} />
              <Typography variant="body1">{lang.name}</Typography>
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default LanguageSwitcher;
