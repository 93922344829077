import React from 'react';
import { Box, Typography } from '@mui/material';

interface NumberedSectionProps {
  number: number;
  side: 'left' | 'right';
  title: string;
  text: string;
}

const NumberedSection: React.FC<NumberedSectionProps> = ({
  number,
  side,
  title,
  text
}) => {

  const isLeftSide = side === 'left';

  return (

    <Box
      sx={{
        position: 'relative',
        p: {xs: 4, md: 8},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        overflow: 'hidden', // Ukrywa nadmiar obrazu
      }}
    >
      {/* Obraz w tle */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          //background: `linear-gradient(to right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0)), url(${imageSrc})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
          zIndex: 1,
          pointerEvents: 'none',
          opacity: 0.9
        }}
      />




      {/* Treść sekcji */}
      <Box
        sx={{
          zIndex: 2, // Treść znajduje się nad obrazem
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'flex-start', md: isLeftSide ? 'flex-start' : 'flex-end' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: { xs: 'flex-start', md: isLeftSide ? 'unset' : 'flex-end' },
            mb: 1,
          }}
        >
          <Typography
            variant="h2"
            component="div"
            sx={{
              fontWeight: 'bold',
              fontSize: '2.2rem',
              color: '#ff7f11',
              lineHeight: '1',
              mr: 2,
            }}
          >
            {number.toString().padStart(2, '0')}.
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontSize: { md: '2.0rem', xs: '1.5rem' },
              lineHeight: '1',
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            fontSize: {
              xs: '1.0rem',
              md: '1.2rem'
            },
            textShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default NumberedSection;
