export const Resource = {
  Buttons: {
    Contact: "buttons.contact",
    PriceList: "buttons.price_list",
    Login: "buttons.login",
    Submit: "buttons.submit",
    Cancel: "buttons.cancel",
    LearnMore: "buttons.learn_more",
    ContactUs: "buttons.contact_us",
    ReadMore: "buttons.read_more",
    Close: "buttons.close",
    SubmitContactForm: "buttons.submit_contact_form",
    Search: "buttons.search",
    Back: "buttons.back",
    Register: "buttons.register",
    ChangeCityButton: "buttons.change_city_button",
    OrderButton: "buttons.order_button",
    SubmitOrderButton: "buttons.submit_order_button",
    TryForFree: "buttons.try_for_free"
  },
  Marketing: {
    Hero: {
      Header: "marketing.hero.header",
      Subheader: "marketing.hero.subHeader"
    },
    Box1: {
      Header: "marketing.box1.header",
      Text: "marketing.box1.text"
    },
    Box2: {
      Header: "marketing.box2.header",
      Text: "marketing.box2.text"
    },
    Box3: {
      Header: "marketing.box3.header",
      Text: "marketing.box3.text"
    }
  },
  Features: {
    Header: "features.header",
    Text1: "features.text_1",
    Text2: "features.text_2",
    BenefitsHeader: "features.benefits_header",
    UsageExamplesHeader: "features.usage_examples_header",
    Feature1: {
      Title: "features.feature_1.title",
      Description: "features.feature_1.description",
      ExtendedDescription: "features.feature_1.extended_description",
      UsageExamples: {
        UsageExample1: "features.feature_1.usage_examples.usage_example_1"
      },
      Benefits: {
        Benefit1: "features.feature_1.benefits.benefit_1",
        Benefit2: "features.feature_1.benefits.benefit_2",
        Benefit3: "features.feature_1.benefits.benefit_3"
      }
    },
    Feature2: {
      Title: "features.feature_2.title",
      Description: "features.feature_2.description",
      ExtendedDescription: "features.feature_2.extended_description",
      UsageExamples: {
        UsageExample1: "features.feature_2.usage_examples.usage_example_1",
        UsageExample2: "features.feature_2.usage_examples.usage_example_2",
        UsageExample3: "features.feature_2.usage_examples.usage_example_3",
        UsageExample4: "features.feature_2.usage_examples.usage_example_4"
      },
      Benefits: {
        Benefit1: "features.feature_2.benefits.benefit_1",
        Benefit2: "features.feature_2.benefits.benefit_2"
      }
    },
    Feature3: {
      Title: "features.feature_3.title",
      Description: "features.feature_3.description",
      ExtendedDescription: "features.feature_3.extended_description",
      UsageExamples: {
        UsageExample1: "features.feature_3.usage_examples.usage_example_1"
      },
      Benefits: {
        Benefit1: "features.feature_3.benefits.benefit_1",
        Benefit2: "features.feature_3.benefits.benefit_2"
      }
    },
    Feature4: {
      Title: "features.feature_4.title",
      Description: "features.feature_4.description",
      ExtendedDescription: "features.feature_4.extended_description",
      UsageExamples: {
        UsageExample1: "features.feature_4.usage_examples.usage_example_1"
      },
      Benefits: {
        Benefit1: "features.feature_4.benefits.benefit_1",
        Benefit2: "features.feature_4.benefits.benefit_2"
      }
    },
    Feature5: {
      Title: "features.feature_5.title",
      Description: "features.feature_5.description",
      ExtendedDescription: "features.feature_5.extended_description",
      Benefits: {
        Benefit1: "features.feature_5.benefits.benefit_1",
        Benefit2: "features.feature_5.benefits.benefit_2"
      }
    },
    Feature6: {
      Title: "features.feature_6.title",
      Description: "features.feature_6.description",
      ExtendedDescription: "features.feature_6.extended_description",
      UsageExamples: {
        UsageExample1: "features.feature_6.usage_examples.usage_example_1"
      },
      Benefits: {
        Benefit1: "features.feature_6.benefits.benefit_1",
        Benefit2: "features.feature_6.benefits.benefit_2"
      }
    },
    Feature7: {
      Title: "features.feature_7.title",
      Description: "features.feature_7.description",
      ExtendedDescription: "features.feature_7.extended_description",
      UsageExamples: {
        UsageExample1: "features.feature_7.usage_examples.usage_example_1"
      },
      Benefits: {
        Benefit1: "features.feature_7.benefits.benefit_1",
        Benefit2: "features.feature_7.benefits.benefit_2",
        Benefit3: "features.feature_7.benefits.benefit_3"
      }
    }
  },
  Benefits: {
    Title: "benefits.title",
    Text1: "benefits.text_1",
    Text2: "benefits.text_2",
    Benefit1: {
      Title: "benefits.benefit_1.title",
      Description: "benefits.benefit_1.description"
    },
    Benefit2: {
      Title: "benefits.benefit_2.title",
      Description: "benefits.benefit_2.description"
    },
    Benefit3: {
      Title: "benefits.benefit_3.title",
      Description: "benefits.benefit_3.description"
    },
    Benefit4: {
      Title: "benefits.benefit_4.title",
      Description: "benefits.benefit_4.description"
    },
    Benefit5: {
      Title: "benefits.benefit_5.title",
      Description: "benefits.benefit_5.description"
    }
  },
  ContactSection: {
    Title: "contact_section.title",
    Description: "contact_section.description",
    SupportText: "contact_section.support_text",
    ContactDetails: {
      PhoneLabel: "contact_section.contact_details.phone_label",
      EmailLabel: "contact_section.contact_details.email_label"
    },
    Form: {
      FormTitle: "contact_section.form.form_title",
      EmailPlaceholder: "contact_section.form.email_placeholder",
      MessagePlaceholder: "contact_section.form.message_placeholder",
      PrivacyPolicy: "contact_section.form.privacy_policy"
    }
  },
  Footer: {
    Login: "footer.login",
    PriceList: "footer.price_list",
    PrivacyPolicy: "footer.privacy_policy",
    TermsConditions: "footer.terms_conditions",
    CopyRights: "footer.copy_rights",
    Faq: "footer.faq"
  },
  CtaTestSection: {
    Title: "cta_test_section.title",
    Subtitle: "cta_test_section.subtitle",
    CtaButton: {
      Text: "cta_test_section.cta_button.text",
      Tooltip: "cta_test_section.cta_button.tooltip"
    }
  },
  Messages: {
    Success: {
      MessageSent: "messages.success.message_sent",
      FormSubmitted: "messages.success.form_submitted",
      OrderPlaced: "messages.success.order_placed"
    },
    Error: {
      SendingFailed: "messages.error.sending_failed",
      GeneralError: "messages.error.general_error",
      FormSubmissionFailed: "messages.error.form_submission_failed",
      ServerError: "messages.error.server_error",
      OrderProcessingFailed: "messages.error.order_processing_failed"
    },
    Info: {
      Loading: "messages.info.loading",
      ContactingServer: "messages.info.contacting_server"
    },
    Warning: {
      UnsavedChanges: "messages.warning.unsaved_changes"
    }
  },
  Pricing: {
    CitySelection: {
      Header: "pricing.city_selection.header",
      Description: "pricing.city_selection.description",
      SelectedCity: "pricing.city_selection.selected_city",
      DefaultCity: "pricing.city_selection.default_city"
    },
    PriceTooltip: "pricing.price_tooltip",
    Plans: {
      Plan1: {
        Title: "pricing.plans.plan1.title",
        Price: "pricing.plans.plan1.price",
        Unit: "pricing.plans.plan1.unit",
        Range: "pricing.plans.plan1.range",
        ProjectsNearby: "pricing.plans.plan1.projects_nearby"
      },
      Plan2: {
        Title: "pricing.plans.plan2.title",
        Price: "pricing.plans.plan2.price",
        Unit: "pricing.plans.plan2.unit",
        Range: "pricing.plans.plan2.range",
        ProjectsNearby: "pricing.plans.plan2.projects_nearby"
      },
      Plan3: {
        Title: "pricing.plans.plan3.title",
        Price: "pricing.plans.plan3.price",
        Unit: "pricing.plans.plan3.unit",
        Range: "pricing.plans.plan3.range",
        ProjectsNearby: "pricing.plans.plan3.projects_nearby"
      },
      Plan4: {
        Title: "pricing.plans.plan4.title",
        Price: "pricing.plans.plan4.price",
        Unit: "pricing.plans.plan4.unit",
        Range: "pricing.plans.plan4.range",
        ProjectsNearby: "pricing.plans.plan4.projects_nearby"
      }
    },
    VatNotice: "pricing.vat_notice",
    FaqNotice: "pricing.faq_notice",
    FaqLink: "pricing.faq_link",
    Features: {
      Header: "pricing.features.header",
      Feature1: {
        Title: "pricing.features.feature1.title",
        Description: "pricing.features.feature1.description"
      },
      Feature2: {
        Title: "pricing.features.feature2.title",
        Description: "pricing.features.feature2.description"
      },
      Feature3: {
        Title: "pricing.features.feature3.title",
        Description: "pricing.features.feature3.description"
      },
      Feature4: {
        Title: "pricing.features.feature4.title",
        Description: "pricing.features.feature4.description"
      },
      Feature5: {
        Title: "pricing.features.feature5.title",
        Description: "pricing.features.feature5.description"
      },
      Feature6: {
        Title: "pricing.features.feature6.title",
        Description: "pricing.features.feature6.description"
      }
    },
    OrderForm: {
      Header: "pricing.order_form.header",
      FormHeader: "pricing.order_form.form_header",
      Description: "pricing.order_form.description",
      Instructions: {
        Instruction1: {
          Title: "pricing.order_form.instructions.instruction1.title",
          Description: "pricing.order_form.instructions.instruction1.description"
        },
        Instruction2: {
          Title: "pricing.order_form.instructions.instruction2.title",
          Description: "pricing.order_form.instructions.instruction2.description"
        },
        Instruction3: {
          Title: "pricing.order_form.instructions.instruction3.title",
          Description: "pricing.order_form.instructions.instruction3.description"
        }
      },
      Fields: {
        Name: "pricing.order_form.fields.name",
        Email: "pricing.order_form.fields.email",
        CompanyName: "pricing.order_form.fields.company_name",
        TaxId: "pricing.order_form.fields.tax_id",
        Address: "pricing.order_form.fields.address",
        Plan: "pricing.order_form.fields.plan"
      },
      Validation: {
        Name: {
          Required: "pricing.order_form.validation.name.required"
        },
        Email: {
          Required: "pricing.order_form.validation.email.required",
          Invalid: "pricing.order_form.validation.email.invalid"
        },
        Company: {
          Required: "pricing.order_form.validation.company.required"
        },
        Address: {
          Required: "pricing.order_form.validation.address.required"
        },
        Plan: {
          Required: "pricing.order_form.validation.plan.required"
        },
        TaxId: {
          Required: "pricing.order_form.validation.tax_id.required"
        }
      },
      Confirmation: {
        Text: "pricing.order_form.confirmation.text",
        TermsLink: "pricing.order_form.confirmation.terms_link",
        TermsAccept: "pricing.order_form.confirmation.terms_accept"
      }
    },
    RangePopup: {
      SetupButton: "pricing.range_popup.setup_button",
      Title: "pricing.range_popup.title",
      SliderTitle: "pricing.range_popup.slider_title"
    }
  },
  Faq: {
    PageTitle: "faq.page_title",
    Intro: "faq.intro",
    Questions: {
      Question1: {
        Title: "faq.questions.question1.title",
        Description: "faq.questions.question1.description"
      },
      Question2: {
        Title: "faq.questions.question2.title",
        Description: "faq.questions.question2.description"
      },
      Question3: {
        Title: "faq.questions.question3.title",
        Description: "faq.questions.question3.description"
      },
      Question4: {
        Title: "faq.questions.question4.title",
        Description: "faq.questions.question4.description"
      }
    },
    ContactCta: {
      Text: "faq.contact_cta.text",
      Button: "faq.contact_cta.button"
    }
  },
  RegistrationForm: {
    Header: "registration_form.header",
    Description: "registration_form.description",
    FormHeader: "registration_form.form_header",
    Fields: {
      Email: "registration_form.fields.email",
      CompanyName: "registration_form.fields.company_name",
      TaxId: "registration_form.fields.tax_id",
      Password: "registration_form.fields.password",
      SearchCity: "registration_form.fields.search_city",
      Code: "registration_form.fields.code"
    },
    Validation: {
      CompanyName: {
        Required: "registration_form.validation.company_name.required"
      },
      TaxId: {
        Required: "registration_form.validation.tax_id.required",
        Invalid: "registration_form.validation.tax_id.invalid"
      },
      Email: {
        Required: "registration_form.validation.email.required",
        Invalid: "registration_form.validation.email.invalid"
      },
      Password: {
        Required: "registration_form.validation.password.required",
        Invalid: "registration_form.validation.password.invalid"
      },
      Code: {
        Invalid: "registration_form.validation.code.invalid"
      }
    },
    PasswordRequirements: {
      Length: "registration_form.password_requirements.length",
      Uppercase: "registration_form.password_requirements.uppercase",
      Lowercase: "registration_form.password_requirements.lowercase",
      Number: "registration_form.password_requirements.number",
      SpecialCharacter: "registration_form.password_requirements.special_character"
    },
    MapInstructions: {
      Main: "registration_form.map_instructions.main",
      Fallback: "registration_form.map_instructions.fallback"
    }
  },
  MapComponent: {
    InputPlaceholder: "map_component.input_placeholder",
    LoadingLocation: "map_component.loading_location",
    DefaultLocationMessage: "map_component.default_location_message",
    UnknownLocation: "map_component.unknown_location",
    ErrorFetchingLocation: "map_component.error_fetching_location",
    MapTooltip: {
      ClickToSelect: "map_component.map_tooltip.click_to_select"
    },
    Popup: {
      Header: "map_component.popup.header",
      ConfirmButton: "map_component.popup.confirm_button",
      CancelButton: "map_component.popup.cancel_button"
    }
  }
} as const;
