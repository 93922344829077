import { Box } from "@mui/material";

const FeatureMedia: React.FC<{
    img?: string;
    video?: string;
    isSmallScreen: boolean;
}> = ({ img, video, isSmallScreen }) => {
    return video ? (
        <video
            src={video}
            autoPlay
            loop
            muted
            playsInline
            style={{
                width: isSmallScreen ? '100%' : '80%',
                height: 'auto',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        />
    ) : (
        <Box
            component="img"
            sx={{
                width: isSmallScreen ? '100%' : '80%',
                height: 'auto',
                maxWidth: '100%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                transition: 'transform 0.3s',
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }}
            src={img}
            alt="Feature media"
        />
    );
};

export default FeatureMedia;
