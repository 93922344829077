import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import heroImage from '../../content/images/hero.png'; // Zaimportowana ścieżka
import { colors } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';


const Hero: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const scrollToContent = () => {
    const element = document.getElementById('content-section');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        justifyContent: 'space-between',
        height: {
          xs: '100vh',
          sm: '90vh',
        },
        background: `linear-gradient(to right, rgba(255, 255, 255, 0.9) 45%, rgba(255, 255, 255, 0.0)), linear-gradient(to bottom, rgba(255, 255, 255, 0.8)  40%, rgba(255, 255, 255, 0.0)), url(${heroImage})`,
        backgroundSize: {
          xs: 'cover',

        },
        backgroundPosition: {
          xs: 'center',
          sm: 'top',
        },
        '@media (orientation: landscape)': {
          height: '100vh',
        },
      }}
    >
      {/* Sekcja tekstowa po lewej */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: {
            xs: 'center',
            sm: 'flex-start',
          },
          alignItems: {
            xs: 'center',
            sm: 'flex-start',
          },
          textAlign: {
            xs: 'center',
            sm: 'left',
          },
          maxWidth: {
            xs: '100%',
            sm: '70%',
            md: '66%',
            lg: '66%',
            xl: '66%',
          },
          '@media (orientation: portrait)': {
            mt: {
              xs: '6vh',
              sm: '16vh',
            },
          },
          '@media (orientation: landscape)': {
            mt: {
              xs: '2vh',
              sm: '2vh',
              md: '2vh',
              lg: '10vh',
              xl: '14vh',
            },
          },

        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: '4.5rem',
              sm: '5.0rem',
              md: '5.5rem',
              lg: '6.0rem',
              xl: '6.6rem',
            },
            fontWeight: 'bold',
            mb: '2vh',
            mt: {
              xs: '16vh',
              sm: '6vh',
            },
            color: `${colors.deepBlue}`,
            textShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            animation: 'fadeIn 1.0s ease-in',
          }}
        >
          NaviBud
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: {
              xs: '1.2rem',
              sm: '1.4rem',
              md: '1.6rem',
              lg: '1.8rem',
              xl: '2.0rem',
            },
            mb: '2vh',
            color: `${colors.primary}`,
            textShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            animation: 'fadeIn 1.5s ease-in',
          }}

        >
          {t(Resource.Marketing.Hero.Header)}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: {
              xs: '0.8rem',
              sm: '1.0rem',
              md: '1.1rem',
              lg: '1.2rem',
              xl: '1.4rem',
            },
            mb: '4vh',
            color: `${colors.primary}`,
            textShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            animation: 'fadeIn 2.0s ease-in',
          }}
        >
          {t(Resource.Marketing.Hero.Subheader)}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: `${colors.warmOrange}`,
              borderRadius: 0,
              margin: 0,
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
            onClick={() => navigate('/testuj-aplikacje')}
          >
            {t(Resource.Buttons.TryForFree)}
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderRadius: 0,
              margin: 0,
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
            onClick={scrollToContent}
          >
            {t(Resource.Buttons.LearnMore)}
          </Button>
        </Box>
      </Box>

      {/* Sekcja graficzna po prawej */}
      <Box
        sx={{
          position: 'relative',
          flex: 1,
          height: '100%',
          overflow: 'hidden',
          display: {
            xs: 'none',
            sm: 'block',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '40%',
            left: '15%',
            width: '14px',
            height: '14px',
            backgroundColor: `${colors.textGray}`,
            borderRadius: '50%',
            animation: 'pulse 2s infinite ease-in-out',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '30%',
            left: '40%',
            width: '20px',
            height: '20px',
            backgroundColor: `${colors.warmOrange}`,
            borderRadius: '50%',
            animation: 'pulse 2s infinite ease-in-out',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '60%',
            width: '36px',
            height: '36px',
            backgroundColor: `${colors.vividBlue}`,
            borderRadius: '50%',
            animation: 'pulse 3s infinite ease-in-out',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '75%',
            left: '20%',
            width: '26px',
            height: '26px',
            backgroundColor: `${colors.warmOrange}`,
            borderRadius: '50%',
            animation: 'pulse 4s infinite ease-in-out',
          }}
        />
      </Box>

      {/* Definicja animacji CSS */}
      <style>
        {`
          @keyframes pulse {
            0%, 100% {
              transform: scale(1);
              opacity: 1;
            }
            50% {
              transform: scale(1.5);
              opacity: 0.5;
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Hero;
