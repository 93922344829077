import React from 'react';
import { Box, Typography, Card, CardContent, CardActionArea } from '@mui/material';
import { colors } from '../../theme';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';

const FAQPage: React.FC = () => {

    const { t } = useTranslation();

    const faqData = [
        {
            question: `${t(Resource.Faq.Questions.Question1.Title)}`,
            answer: `${t(Resource.Faq.Questions.Question1.Description)}`,
        },
        {
            question: `${t(Resource.Faq.Questions.Question2.Title)}`,
            answer: `${t(Resource.Faq.Questions.Question2.Description)}`,
        },
        {
            question: `${t(Resource.Faq.Questions.Question3.Title)}`,
            answer: `${t(Resource.Faq.Questions.Question3.Description)}`,
        },
        {
            question: `${t(Resource.Faq.Questions.Question4.Title)}`,
            answer: `${t(Resource.Faq.Questions.Question4.Description)}`,
        },
    ];

    return (
        <Box
            sx={{
                padding: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '90vh',
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    fontSize: {
                        xs: '1.5rem',
                        sm: '1.8rem',
                        md: '2.0rem',
                        lg: '2.5rem',
                        xl: '3.0rem'
                    },
                    textAlign: 'center',
                    mb: '30px',
                }}
            >
                {t(Resource.Faq.PageTitle)}
            </Typography>

            <Typography
                variant="h5"
                sx={{
                    fontSize: {
                        xs: '0.9rem',
                        sm: '1.0rem',
                        md: '1.2rem',
                        lg: '1.5rem',
                        xl: '1.7rem'
                    },
                    textAlign: 'center',
                    mb: '30px',
                }}
            >
                {t(Resource.Faq.Intro)}
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                    gap: 3,
                    width: '100%',
                    maxWidth: '1200px',
                }}
            >
                {faqData.map((faq, index) => (
                    <Card
                        key={index}
                        sx={{
                            borderRadius: 2,
                            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                            overflow: 'hidden',
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: '0px 6px 25px rgba(0, 0, 0, 0.15)',
                            },
                        }}
                    >
                        <CardActionArea sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}>
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.warmOrange,
                                    }}
                                >
                                    {faq.question}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: colors.textGray,
                                        marginTop: 2,
                                    }}
                                >
                                    {faq.answer}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

export default FAQPage;
