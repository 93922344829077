import React, { RefObject } from 'react';
import { Box, Grid, Typography, IconButton, Link, Stack } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import logoFooter from '../../content/images/logo_footer.png';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear(); 
  const { t } = useTranslation();

  return (
    <Box sx={{ bgcolor: 'black', color: 'white', py: 3, px: 4, textAlign: 'center' }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <img src={logoFooter} alt="Navibud - Logo" style={{ width: 'auto', height: '70px', marginBottom: 2 }} />

        </Grid>
        <Grid item xs={12}>
          <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 3 }}
              justifyContent="center"
              alignItems="center"
           >
            {/* <Link href="https://app.navi-bud.pl/register" rel="noopener noreferrer" title="Zarejestruj się w NaviBud" underline="hover" color="inherit" sx={{ py: 1 }}>
              Rejestracja
            </Link>*/}
            <Link href="https://app.navi-bud.pl/login" rel="noopener noreferrer" title="Zaloguj się do NaviBud" underline="hover" color="inherit" sx={{ py: 1 }}>
            {t(Resource.Footer.Login)} 
            </Link> 
            <Link href="/cennik" rel="noopener noreferrer" title="NaviBud - cennik" underline="hover" color="inherit" sx={{ py: 1 }}>
            {t(Resource.Footer.PriceList)} 
            </Link>
            <Link href="/polityka-prywatnosci" rel="noopener noreferrer" title="NaviBud - polityka prywatności" underline="hover" color="inherit" sx={{ py: 1 }}>
            {t(Resource.Footer.PrivacyPolicy)} 
            </Link>
            <Link href="/regulamin" rel="noopener noreferrer" title="NaviBud - regulamin" underline="hover" color="inherit" sx={{ py: 1 }}>
            {t(Resource.Footer.TermsConditions)} 
            </Link> 
            <Link href="/faq" rel="noopener noreferrer" title="NaviBud - regulamin" underline="hover" color="inherit" sx={{ py: 1 }}>
            {t(Resource.Footer.Faq)} 
            </Link> 
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {/* <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2, mb: 1 }}>
            <IconButton color="inherit" onClick={() => window.open('https://youtube.com', '_blank')}>
              <YouTubeIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => window.open('https://x.com', '_blank')}>
              <TwitterIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => window.open('https://instagram.com', '_blank')}>
              <InstagramIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => window.open('https://linkedin.com', '_blank')}>
              <LinkedInIcon />
            </IconButton> 
          </Stack> */}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ mt: 1 }}>© {currentYear} NaviBud. {t(Resource.Footer.CopyRights)} </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;