import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardActions, Button, Typography, Box, Slider, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Tooltip } from '@mui/material';
import { colors } from '../../theme';
import { API_ENDPOINTS } from '../../utils/apiConfig';
import { useSpring, animated } from '@react-spring/web';
import { Position } from '../Map/MapComponent';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';
import InfoIcon from '@mui/icons-material/Info';

interface SubscriptionPlan {
  id: number;
  name: string;
  price: string;
  range: string;
  details: string[];
  color: string;
  inactiveColorLight: string;
  inactiveColorDark: string;
  hasSlider?: boolean;
  isBuyable?: boolean;
  radius: number;
}

interface SubscriptionCardProps {
  plan: SubscriptionPlan;
  selected: boolean;
  onSelect: (id: number, name: string, radius: number) => void;
  onScrollToForm: () => void;
  coordinates: Position;
  selectedCityName: string | null;
  onCitySelect: (position: Position, cityName: string | null) => void;
  initialCount: number | null;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  plan, selected, onSelect, onScrollToForm, coordinates, selectedCityName, onCitySelect, initialCount }) => {

  const { t } = useTranslation();

  const [sliderValue, setSliderValue] = useState<number>(161);
  const [open, setOpen] = useState(false);
  const [investmentCount, setInvestmentCount] = useState<number | null>(initialCount);
  const [loading, setLoading] = useState<boolean>(true);

  const animatedInvestmentCount = useSpring({
    from: { number: 0 },
    to: { number: investmentCount || 0 },
    config: { duration: 2000 },
  });

  useEffect(() => {
    if (initialCount === null) {
      fetchInvestmentCount(sliderValue);
    } else {
      setInvestmentCount(initialCount);
      setLoading(false); // Stop loading if initial count is provided
    }
  }, [initialCount]);

  const fetchInvestmentCount = async (radius: number) => {
    try {
      setLoading(true);
      const url = `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.COUNT_INVESTMENTS}?latitude=${coordinates.lat}&longitude=${coordinates.lng}&distanceInKm=${radius}`;
      const response = await fetch(url);

      if (!response.ok) throw new Error('Failed to fetch investment count');

      const count = await response.json();
      setInvestmentCount(count);
    } catch (error) {
      console.error('Error fetching investment count:', error);
      setInvestmentCount(null);
    } finally {
      setLoading(false);
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number);
  };

  const handleCardClick = () => {
    if (plan.id === 4) {
      setOpen(true);
    } else {
      fetchInvestmentCount(plan.radius);
      onSelect(plan.id, plan.name, plan.radius);
    }
  };

  const handleOrderButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleCardClick(); // Ensure the plan is selected
    setOpen(false);
    onScrollToForm(); // Scroll to the form
  };

  const handleClose = () => {
    setOpen(false);
    onSelect(plan.id, plan.name, sliderValue);
  };

  const handleConfirmSlider = () => {
    setOpen(false);
    fetchInvestmentCount(sliderValue);
    onSelect(plan.id, plan.name, sliderValue);
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          cursor: 'pointer',
          mb: 2,
          '&:hover': {
            boxShadow: 4,
          },
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          borderRadius: '4px',
          border: `${selected ? '2px' : '1px'} solid ${selected ? '#FF5722' : '#ccc'}`,
          boxShadow: selected ? 8 : 1,
          transition: 'box-shadow 0.1s',
        }}
        onClick={handleCardClick}
      >
        <Box sx={{
          color: '#000',
          textAlign: 'center',
          p: 2
        }}>
          <Typography variant="h5" component="div"
            sx={{
              fontSize: {
                xs: '1.3rem',
                md: '1.0rem',
                lg: '1.3rem'
              },
            }}>
            {plan.name}
          </Typography>
          <Typography variant="body1" component="div"
            sx={{
              fontSize: {
                xs: '1.5rem',
                md: '1.4rem',
              },
              fontWeight: 'bold',
              marginTop: 2,
              color: colors.warmOrange
            }}>
            {plan.price}
            <Tooltip
              title={t(Resource.Pricing.PriceTooltip)}
              arrow
            >
              <InfoIcon sx={{
                fontSize: {
                  xs: '1.1rem',
                  md: '1.0rem',
                }, marginLeft: 1, marginTop: 2, color: colors.textGray
              }} />
            </Tooltip>
          </Typography>
          <Typography variant="body1" component="div"
            sx={{
              fontSize: {
                xs: '0.7rem',
                md: '0.8rem',
              },
              color: colors.textGray
            }}>
            {t(Resource.Pricing.Plans.Plan1.Unit)} / 
            <br/> roczny abonament
          </Typography>
        </Box>

        <CardContent sx={{
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          <Box>
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center'
              }}>
              {plan.range}
            </Typography>
            {plan.hasSlider && (
              <Box sx={{ mb: 0 }}>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'center'
                  }}>{t(Resource.Pricing.Plans.Plan4.Range)} {sliderValue} km</Typography>
              </Box>
            )}
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                textAlign: 'center'
              }}
            >
              {t(Resource.Pricing.Plans.Plan1.ProjectsNearby)}
              <br />
              {loading
                ? <CircularProgress size={20} sx={{ color: colors.warmOrange }} />
                : <animated.span style={{ fontSize: '1.3rem', color: colors.warmOrange, fontWeight: 'bold' }}>{animatedInvestmentCount.number.to((n) => Math.round(n))}</animated.span>}
            </Typography>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            bgcolor: '#fff',
            justifyContent: 'center',
            p: 1,
            flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons vertically on smaller screens
          }}
        >
          <Button
            sx={{
              borderColor: colors.warmOrange,
              color: '#fff',
              backgroundColor: colors.warmOrange,
              width: { xs: '100%', sm: '60%' }, // Full width on small screens, 60% on larger screens
              mt: 1,
              '&:hover': {
                borderColor: colors.warmOrange,
                bgColor: colors.warmOrange,
                color: colors.textGray
              },
              borderRadius: 0,
            }}
            size="small"
            variant="outlined"
            onClick={handleOrderButtonClick}
          >
            {t(Resource.Buttons.OrderButton)}
          </Button>
        </CardActions>
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t(Resource.Pricing.RangePopup.Title)}</DialogTitle>
        <DialogContent>
          <Typography variant="body2">{t(Resource.Pricing.RangePopup.SliderTitle)} {sliderValue} km</Typography>
          <Slider
            value={sliderValue}
            onChange={handleSliderChange}
            aria-labelledby="continuous-slider"
            min={0}
            max={200}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmSlider} color="primary">
          {t(Resource.Pricing.RangePopup.SetupButton)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubscriptionCard;
