import React, { useState } from 'react';
import { Box, Grid, Typography, useTheme, useMediaQuery, Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import coloredMap from '../../content/images/keyFeatures/coloredMap_white.webp';
import editMarker from '../../content/images/keyFeatures/editMarker_white.webp';
import navigationImg from '../../content/images/keyFeatures/navigation.webp';
import calendarImg from '../../content/images/keyFeatures/calendar.png';

import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';
import FeatureMedia from './FeatureMedia';
import FeatureText from './FeatureText';
import { colors } from '../../theme';


const KeyFeaturesSection: React.FC = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState<null | typeof keyFeatures[0]>(null);
    const { t } = useTranslation();

    const handleClickOpen = (feature: typeof keyFeatures[0]) => {
        setSelectedFeature(feature);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFeature(null);
    };

    const keyFeatures = [
        {
            title: `${t(Resource.Features.Feature1.Title)}`,
            content: `${t(Resource.Features.Feature1.Description)}`,
            moreContent: `${t(Resource.Features.Feature1.ExtendedDescription)}`,
            usageExamples: [
                `${t(Resource.Features.Feature1.UsageExamples.UsageExample1)}`
            ],
            benefits: [
                `${t(Resource.Features.Feature1.Benefits.Benefit1)}`,
                `${t(Resource.Features.Feature1.Benefits.Benefit2)}`,
                `${t(Resource.Features.Feature1.Benefits.Benefit3)}`
            ],
            img: coloredMap,
        },
        {
            title: `${t(Resource.Features.Feature2.Title)}`,
            content: `${t(Resource.Features.Feature2.Description)}`,
            moreContent: `${t(Resource.Features.Feature2.ExtendedDescription)}`,
            usageExamples: [
                `${t(Resource.Features.Feature2.UsageExamples.UsageExample1)}`,
                `${t(Resource.Features.Feature2.UsageExamples.UsageExample2)}`,
                `${t(Resource.Features.Feature2.UsageExamples.UsageExample3)}`,
                `${t(Resource.Features.Feature2.UsageExamples.UsageExample4)}`
            ],
            benefits: [
                `${t(Resource.Features.Feature2.Benefits.Benefit1)}`,
                `${t(Resource.Features.Feature2.Benefits.Benefit2)}`
            ],
            img: editMarker,
            video: '/videos/statuses.mp4'
        },
        {
            title: `${t(Resource.Features.Feature3.Title)}`,
            content: `${t(Resource.Features.Feature3.Description)}`,
            moreContent: `${t(Resource.Features.Feature3.ExtendedDescription)}`,
            usageExamples: [
                `${t(Resource.Features.Feature3.UsageExamples.UsageExample1)}`
            ],
            benefits: [
                `${t(Resource.Features.Feature3.Benefits.Benefit1)}`,
                `${t(Resource.Features.Feature3.Benefits.Benefit2)}`
            ],
            video: '/videos/filtering.mp4'
        },
        {
            title: `${t(Resource.Features.Feature4.Title)}`,
            content: `${t(Resource.Features.Feature4.Description)}`,
            moreContent: `${t(Resource.Features.Feature4.ExtendedDescription)}`,
            usageExamples: [
                `${t(Resource.Features.Feature4.UsageExamples.UsageExample1)}`
            ],
            benefits: [
                `${t(Resource.Features.Feature4.Benefits.Benefit1)}`,
                `${t(Resource.Features.Feature4.Benefits.Benefit2)}`
            ],
            img: navigationImg,
        },
        {
            title: `${t(Resource.Features.Feature5.Title)}`,
            content: `${t(Resource.Features.Feature5.Description)}`,
            moreContent: `${t(Resource.Features.Feature5.ExtendedDescription)}`,
            usageExamples: [],
            benefits: [
                `${t(Resource.Features.Feature5.Benefits.Benefit1)}`,
                `${t(Resource.Features.Feature5.Benefits.Benefit2)}`
            ],
            video: '/videos/multiple_devices.mp4'
        },
        {
            title: `${t(Resource.Features.Feature6.Title)}`,
            content: `${t(Resource.Features.Feature6.Description)}`,
            moreContent: `${t(Resource.Features.Feature6.ExtendedDescription)}`,
            usageExamples: [
                `${t(Resource.Features.Feature6.UsageExamples.UsageExample1)}`
            ],
            benefits: [
                `${t(Resource.Features.Feature6.Benefits.Benefit1)}`,
                `${t(Resource.Features.Feature6.Benefits.Benefit2)}`
            ],
            img: calendarImg,
        },
        {
            title: `${t(Resource.Features.Feature7.Title)}`,
            content: `${t(Resource.Features.Feature7.Description)}`,
            moreContent: `${t(Resource.Features.Feature7.ExtendedDescription)}`,
            usageExamples: [
                `${t(Resource.Features.Feature7.UsageExamples.UsageExample1)}`
            ],
            benefits: [
                `${t(Resource.Features.Feature7.Benefits.Benefit1)}`,
                `${t(Resource.Features.Feature7.Benefits.Benefit2)}`,
                `${t(Resource.Features.Feature7.Benefits.Benefit3)}`
            ],
            img: editMarker,
        }
    ];


    return (
        <Box id="content-section" sx={{ width: '100%', marginBottom: '10px' }}>
            <Typography variant="h3" component="h2" gutterBottom
                sx={{
                    fontSize: {
                        xs: '1.8rem',
                        md: '2.5rem',
                        lg: '3.0rem'
                    },
                    mb: 5,
                    textAlign: 'center'
                }}>
                {t(Resource.Features.Header)}
            </Typography>
            <Typography variant="body1"
                sx={{
                    color: '#333',
                    lineHeight: 1.8,
                    fontSize: {
                        xs: '1.0rem',
                        md: '1.2rem'
                    },
                    mb: '60px',
                    textAlign: 'center'
                }}>
                {t(Resource.Features.Text1)}
                <br />
                {t(Resource.Features.Text2)}
            </Typography>
            {keyFeatures.map((feature, index) => (
                <Grid
                    container
                    spacing={3}
                    key={index}
                    sx={{
                        width: '100%',
                        marginLeft: 0,
                        mb: 4,
                        backgroundColor: index % 2 === 0 ? `${colors.lightGray}` : '#ffffff',
                        paddingRight: 4,
                    }}
                >
                    {index % 2 === 0 ? (
                        <>
                            <Grid
                                item
                                xs={12}
                                md={8}
                                padding={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                }}
                            >
                                <FeatureMedia
                                    img={feature.img}
                                    video={feature.video}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    paddingLeft: theme.spacing(3),

                                }}
                            >
                                <FeatureText
                                    title={feature.title}
                                    content={feature.content}
                                    onReadMore={() => handleClickOpen(feature)}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    paddingLeft: theme.spacing(3),
                                    order: isSmallScreen ? 2 : index % 2 === 0 ? 2 : 1,
                                }}
                            >
                                <FeatureText
                                    title={feature.title}
                                    content={feature.content}
                                    onReadMore={() => handleClickOpen(feature)}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={8}
                                padding={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: { xs: 'center', md: 'flex-end' },
                                    alignItems: 'center',
                                    order: isSmallScreen ? 1 : index % 2 === 0 ? 1 : 2,
                                }}
                            >
                                <FeatureMedia
                                    img={feature.img}
                                    video={feature.video}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            ))}
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle
                    sx={{
                        bgcolor: colors.warmOrange, // WarmOrange
                        color: '#fff',
                        fontWeight: 'bold',
                        padding: '16px',
                        textAlign: 'center',
                    }}
                >
                    {selectedFeature?.title}
                </DialogTitle>
                <DialogContent
                    sx={{
                        bgcolor: colors.lightGray, // Light gray background
                        padding: '24px',
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#333',
                            lineHeight: 1.8,
                            fontSize: '1rem',
                            mb: 3,
                            textAlign: 'justify',
                        }}
                    >
                        {selectedFeature?.moreContent}
                    </Typography>

                    {/* Korzyści */}
                    {selectedFeature != null && selectedFeature?.benefits?.length > 0 && (
                        <Box mb={3}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ color: colors.deepBlue, fontWeight: 'bold', mb: 1 }}
                            >
                                {t(Resource.Features.BenefitsHeader)}
                            </Typography>
                            <ul style={{ margin: 0, padding: '0 16px' }}>
                                {selectedFeature != null && selectedFeature.benefits.map((benefit, index) => (
                                    <li key={index} style={{ marginBottom: '8px' }}>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: '#555',
                                                lineHeight: 1.6,
                                            }}
                                        >
                                            {benefit}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </Box>
                    )}

                    {/* Przykłady użycia */}
                    {selectedFeature != null && selectedFeature?.usageExamples?.length > 0 && (
                        <Box mb={3}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ color: '#0e4999', fontWeight: 'bold', mb: 1 }}
                            >
                                {t(Resource.Features.UsageExamplesHeader)}
                            </Typography>
                            <ul style={{ margin: 0, padding: '0 16px' }}>
                                {selectedFeature != null && selectedFeature.usageExamples.map((example, index) => (
                                    <li key={index} style={{ marginBottom: '8px' }}>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: '#555',
                                                lineHeight: 1.6,
                                            }}
                                        >
                                            {example}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        bgcolor: '#f1f1f1', // Slightly darker gray
                        padding: '16px',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        onClick={handleClose}
                        sx={{
                            bgcolor: '#ff7f11', // WarmOrange
                            color: '#fff',
                            borderRadius: '4px',
                            padding: '8px 16px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            '&:hover': {
                                bgcolor: '#e5700f', // Slightly darker WarmOrange
                            },
                        }}
                    >
                        {t(Resource.Buttons.Close)}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default KeyFeaturesSection;
