import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import MapComponent, { Position } from '../Map/MapComponent';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';

interface MapPopupProps {
  open: boolean;
  onClose: () => void;
  position: Position;
  onLocationSelect: (position: Position, cityName: string | null) => void;
  onConfirm: (position: Position, cityName: string | null) => void;
  radius: number | null;
}

const MapPopup: React.FC<MapPopupProps> = ({ open, onClose, position, onLocationSelect, onConfirm, radius }) => {
  const [localPosition, setLocalPosition] = useState<Position>(position);
  const [localCityName, setLocalCityName] = useState<string | null>(null); // Track city name locally
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm(localPosition, localCityName);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          overflow: 'hidden', // Prevents the scrollbar
        },
      }}
    >
      <DialogTitle>{t(Resource.MapComponent.Popup.Header)}</DialogTitle>
      <DialogContent sx={{ p: 2, height: { xs: '50vh', lg: '54vh' } }}>
        <Box sx={{ mt: 1, height: '400px' }}>
          <MapComponent
            position={localPosition}
            onLocationSelect={(pos, cityName) => {
              setLocalPosition(pos);
              setLocalCityName(cityName);
            }}
            radius={radius}
            mapHeight="400px"
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button onClick={onClose} color="secondary">
          {t(Resource.Buttons.Cancel)}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {t(Resource.Buttons.Submit)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapPopup;
