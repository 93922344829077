import React from 'react';
import { Box, Typography, ListItem, ListItemIcon, ListItemText, Grid } from '@mui/material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import StarIcon from '@mui/icons-material/Star';
import DatasetIcon from '@mui/icons-material/Dataset';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { colors } from '../../theme';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';

const SubscriptionDetail: React.FC = () => {
  const { t } = useTranslation();

  const features = [
    {
      name: `${t(Resource.Pricing.Features.Feature1.Title)}`,
      icon: <TipsAndUpdatesIcon sx={{ color: colors.warmOrange, fontSize: 40 }} />,
      description: `${t(Resource.Pricing.Features.Feature1.Description)}`
    },
    {
      name: `${t(Resource.Pricing.Features.Feature2.Title)}`,
      icon: <StarIcon sx={{ color: colors.warmOrange, fontSize: 40 }} />,
      description: `${t(Resource.Pricing.Features.Feature2.Description)}`
    },
    {
      name: `${t(Resource.Pricing.Features.Feature3.Title)}`,
      icon: <DatasetIcon sx={{ color: colors.warmOrange, fontSize: 40 }} />,
      description: `${t(Resource.Pricing.Features.Feature3.Description)}`
    },
    {
      name: `${t(Resource.Pricing.Features.Feature4.Title)}`,
      icon: <AltRouteIcon sx={{ color: colors.warmOrange, fontSize: 40 }} />,
      description: `${t(Resource.Pricing.Features.Feature4.Description)}`
    },
    {
      name: `${t(Resource.Pricing.Features.Feature5.Title)}`,
      icon: <ManageHistoryIcon sx={{ color: colors.warmOrange, fontSize: 40 }} />,
      description: `${t(Resource.Pricing.Features.Feature5.Description)}`
    },
    {
      name: `${t(Resource.Pricing.Features.Feature6.Title)}`,
      icon: <LocalOfferIcon sx={{ color: colors.warmOrange, fontSize: 40 }} />,
      description: `${t(Resource.Pricing.Features.Feature6.Description)}`
    }
  ];

  return (
    <Box sx={{ my: 4, textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 6 }}>
        {t(Resource.Pricing.Features.Header)}
      </Typography>
      <Box sx={{ border: `1px solid ${colors.lightGray}`, borderColor: colors.lightGray, bgcolor: colors.lightGray, mt: 2 }}>
        <Grid container spacing={2} sx={{ ml: 0, width: '100%' }}>
          {features.map((feature, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              sx={{
                backgroundColor: '#fff',
                padding: 2,
                border: `1px solid ${colors.lightGray}`
              }}
            >
              <ListItem>
                <ListItemIcon>
                  {feature.icon}
                </ListItemIcon>
                <ListItemText primary={feature.name} secondary={feature.description} />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SubscriptionDetail;
