import { Button, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next";
import { Resource } from "../../resources";

const FeatureText: React.FC<{
  title: string;
  content: string;
  onReadMore: () => void;
}> = ({ title, content, onReadMore }) => {
   const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="h5"
        component="p"
        sx={{ mb: 2, color: '#0e4999', fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      <Typography variant="body1" textAlign="justify">
        {content}
      </Typography>
      <Button
        size="small"
        startIcon={<InfoIcon />}
        sx={{
          fontSize: '0.875rem',
          color: '#ff7f11',
          mt: 2,
          mb: 4,
        }}
        onClick={onReadMore}
      >
       {t(Resource.Buttons.ReadMore)}
      </Button>
    </>
  );
};

export default FeatureText;
