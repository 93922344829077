import React, { forwardRef } from 'react';
import { Box, Button, TextField, Typography, Grid, Snackbar, Alert, Container, FormControlLabel, Checkbox, Backdrop, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AlertColor } from '@mui/material/Alert';
import { colors } from '../../theme';
import { API_ENDPOINTS } from '../../utils/apiConfig';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CampaignIcon from '@mui/icons-material/Campaign';

interface FormValues {
  email: string;
  message: string;
  acceptedPolicy: boolean;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Niepoprawny adres email').required('Email jest wymagany'),
  message: Yup.string().required('Wiadomość jest wymagana'),
  acceptedPolicy: Yup.boolean()
    .required('Musisz zaakceptować politykę prywatności.')
    .oneOf([true], 'Musisz zaakceptować politykę prywatności.')
});

const ContactFormSection = forwardRef<HTMLDivElement>((props, ref) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>('success');
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: '',
      message: '',
      acceptedPolicy: false
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      handleFormSubmit(values, setSubmitting, resetForm);
    }
  });

  const handleFormSubmit = async (values: FormValues, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
    try {
      const url = `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.CONTACT}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values)
      });

      if (!response.ok) throw new Error(`${t(Resource.Messages.Error.SendingFailed)}`);

      setSnackbarMessage(`${t(Resource.Messages.Success.MessageSent)}`);
      setSnackbarSeverity('success');
      resetForm();
    } catch (error) {
      setSnackbarMessage(`${t(Resource.Messages.Error.SendingFailed)}`);
      setSnackbarSeverity('error');
    } finally {
      setSubmitting(false);
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Grid container spacing={2} ref={ref} id="contactSection"
      sx={{
        ml: 0,
        width: '100%',
        mt: 4,
        mb: 4
      }}>

      <Grid item xs={12} md={6} sx={{ bgcolor: colors.lightGray, border: '1px solid #ccc' }}>
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            paddingLeft: 0,
          }}
        >
          <Grid container >
            <Grid item xs={10}>
              <Box >
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {t(Resource.ContactSection.Title)}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {t(Resource.ContactSection.Description)}
                </Typography>
                <Typography variant="body2" sx={{ mb: 4 }}>
                  {t(Resource.ContactSection.SupportText)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CampaignIcon
                    sx={{
                      fontSize: 100,
                      color: colors.warmOrange,
                      transform: 'rotate(-40deg)'
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PhoneIcon sx={{ fontSize: 40, color: colors.warmOrange, mr: 2 }} />
                  <Box>
                    <Typography variant="body2">
                      {t(Resource.ContactSection.ContactDetails.PhoneLabel)}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '1rem', md: '1.25rem' },
                      }}
                    >
                      +48 453 097 788
                    </Typography>
                  </Box>

                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <EmailIcon sx={{ fontSize: 40, color: colors.warmOrange, mr: 2 }} />
                  <Box>
                    <Typography variant="body2">
                      {t(Resource.ContactSection.ContactDetails.EmailLabel)}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '1rem', md: '1.25rem' },
                      }}
                    >
                      kontakt@navi-bud.pl
                    </Typography>
                  </Box>

                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ padding: 2, display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            paddingLeft: 0,
          }}>
          <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {t(Resource.ContactSection.Form.FormTitle)}
            </Typography>
            <TextField
              fullWidth
              name="email"
              label={t(Resource.ContactSection.Form.EmailPlaceholder)}
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              name="message"
              label={t(Resource.ContactSection.Form.MessagePlaceholder)}
              multiline
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="acceptedPolicy"
                  checked={formik.values.acceptedPolicy}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
              label={t(Resource.ContactSection.Form.PrivacyPolicy)}
            />
            {formik.touched.acceptedPolicy && formik.errors.acceptedPolicy && (
              <Typography color="error" variant="body2">
                {formik.errors.acceptedPolicy}
              </Typography>
            )}
            <Button type="submit" variant="contained" fullWidth sx={{ bgcolor: '#ff7f11', borderRadius: 0 }}>
              {t(Resource.Buttons.SubmitContactForm)}
            </Button>
            {loading && (
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </form>
        </Box>
      </Grid>


      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
});

export default ContactFormSection;
