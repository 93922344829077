import { useEffect, useState } from 'react';
import { Alert, AlertColor, Backdrop, Box, Button, CircularProgress, Grid, List, ListItem, ListItemIcon, Snackbar, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import MapComponent, { Position } from '../Map/MapComponent';
import { API_ENDPOINTS } from '../../utils/apiConfig';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';


const TestAppForm = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState<AlertColor>('success'); // 'success' or 'error'
    const [loading, setLoading] = useState(false);
    const [coordinates, setCoordinates] = useState<Position | null>(null);
    const { t } = useTranslation();

    const handleMapChange = (position: Position) => {
        setCoordinates(position);
    };

    const handleCancel = () => {
        formik.resetForm(); // Czyści formularz
        navigate('/'); // Przenosi do głównej strony
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const navigate = useNavigate();

    const validationSchema = Yup.object({
        companyName: Yup.string().required(`${t(Resource.RegistrationForm.Validation.CompanyName.Required)}`),
        nip: Yup.string()
            .matches(/^[0-9]{10}$/, `${t(Resource.RegistrationForm.Validation.TaxId.Invalid)}`)
            .required(`${t(Resource.RegistrationForm.Validation.TaxId.Required)}`),
        email: Yup.string()
            .email(`${t(Resource.RegistrationForm.Validation.Email.Invalid)}`)
            .required(`${t(Resource.RegistrationForm.Validation.Email.Required)}`),
        password: Yup.string()
            .required(`${t(Resource.RegistrationForm.Validation.Password.Required)}`)
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,;:])[A-Za-z\d@$!%*?&.,;:]{8,}$/,
                `${t(Resource.RegistrationForm.Validation.Password.Invalid)}`
            ),
            code: Yup.string()
            .matches(/^[A-Za-z0-9]{3}-[A-Za-z0-9]{3}-[A-Za-z0-9]{3}$/, `${t(Resource.RegistrationForm.Validation.Code.Invalid)}`)
            .notRequired(),
    });

    const formik = useFormik({
        initialValues: {
            companyName: '',
            nip: '',
            email: '',
            password: '',
            code: ''
        },
        validationSchema: validationSchema,

        onSubmit: async (values, { resetForm }) => {
            const url = `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.REGISTER_TEST}`;
            const userCoordinates = await getUserLocation();
            const finalCoordinates = coordinates || userCoordinates;
            const payload = {
                user: { userName: values.email, password: values.password, email: values.email },
                position: { latitude: finalCoordinates.lat, longitude: finalCoordinates.lng },
                company: { taxId: values.nip, name: values.companyName },
                code: values.code
            };
            setLoading(true);
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.message || "Unknown error");
                }

                const data = await response.json();
                setSnackbarMessage(`${t(Resource.Messages.Success.FormSubmitted)}`);
                setSnackbarType('success');
                setSnackbarOpen(true);
                resetForm();
            } catch (error: unknown) {
                let errorMessage = `${t(Resource.Messages.Error.FormSubmissionFailed)}`;
                if (error instanceof Error) {
                    errorMessage = `${t(Resource.Messages.Error.ServerError)} ` + error.message;
                }
                setSnackbarMessage(errorMessage);
                setSnackbarType('error');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        },
    });

    const getDefaultLocation = () => {
        // Domyślna lokalizacja - Kalisz
        return { lat: 51.7666636, lng: 18.083333 };
    };

    const getUserLocation = () => {
        return new Promise<Position>((resolve) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
                },
                () => {
                    resolve(getDefaultLocation());
                }
            );
        });
    };

    const checkPasswordCriteria = {
        length: formik.values.password.length >= 8,
        upper: /[A-Z]/.test(formik.values.password),
        lower: /[a-z]/.test(formik.values.password),
        number: /\d/.test(formik.values.password),
        special: /[@$!%*?&]/.test(formik.values.password),
    };


    return (
        <Box sx={{ p: 2, height: '100%' }}>
            <Typography variant="h4" sx={{ mb: 4 }}>{t(Resource.RegistrationForm.Header)}</Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>{t(Resource.RegistrationForm.Description)}</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} sx={{ alignItems: 'flex-start' }}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>{t(Resource.RegistrationForm.FormHeader)}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label={t(Resource.RegistrationForm.Fields.Email)}
                                    type="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="companyName"
                                    name="companyName"
                                    label={t(Resource.RegistrationForm.Fields.CompanyName)}
                                    value={formik.values.companyName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                    helperText={formik.touched.companyName && formik.errors.companyName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="nip"
                                    name="nip"
                                    label={t(Resource.RegistrationForm.Fields.TaxId)}
                                    value={formik.values.nip}
                                    onChange={formik.handleChange}
                                    error={formik.touched.nip && Boolean(formik.errors.nip)}
                                    helperText={formik.touched.nip && formik.errors.nip}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label={t(Resource.RegistrationForm.Fields.Password)}
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                                <List>
                                    {Object.entries(checkPasswordCriteria).map(([key, isValid]) => (
                                        <ListItem key={key} disablePadding>
                                            <ListItemIcon>
                                                {isValid && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                                            </ListItemIcon>
                                            <Typography sx={{ fontSize: '0.75rem' }}>
                                                {key === 'length' ? `${t(Resource.RegistrationForm.PasswordRequirements.Length)}` :
                                                    key === 'upper' ? `${t(Resource.RegistrationForm.PasswordRequirements.Uppercase)}` :
                                                        key === 'lower' ? `${t(Resource.RegistrationForm.PasswordRequirements.Lowercase)}` :
                                                            key === 'number' ? `${t(Resource.RegistrationForm.PasswordRequirements.Number)}` :
                                                                `${t(Resource.RegistrationForm.PasswordRequirements.SpecialCharacter)}`}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="code"
                                    name="code"
                                    label={t(Resource.RegistrationForm.Fields.Code)}
                                    value={formik.values.code}
                                    onChange={formik.handleChange}
                                    error={formik.touched.code && Boolean(formik.errors.code)}
                                    helperText={formik.touched.code && formik.errors.code}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" sx={{ mb: 2, fontSize: '0.9em' }}>
                            {t(Resource.RegistrationForm.MapInstructions.Main)}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2, fontSize: '0.7em' }}>
                            {t(Resource.RegistrationForm.MapInstructions.Fallback)}
                        </Typography>
                        <MapComponent position={coordinates} onLocationSelect={handleMapChange} radius={10} mapHeight='250px' />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
                        <Button variant="outlined"
                            sx={{
                                borderRadius: 0,
                                '&:hover': {
                                    color: '#ff7f11',
                                    borderColor: '#ff7f11'
                                }
                            }} onClick={handleCancel}>{t(Resource.Buttons.Back)}</Button>
                        <Button type="submit" variant="contained" sx={{ ml: 5, backgroundColor: '#ff7f11', borderRadius: 0 }}>{t(Resource.Buttons.Register)}</Button>
                    </Grid>
                </Grid>
                {loading && (
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </form>
            <Snackbar open={snackbarOpen} autoHideDuration={8000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );

};

export default TestAppForm;
