import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import SubscriptionCard from './SubscriptionCard';
import { colors } from '../../theme';
import { Position } from '../Map/MapComponent';
import PaymentFormSection from '../PaymentFormSection/PaymentFormSection';
import TestAppBottomSection from '../TestAppSection/TestAppBottomSection';
import SubscriptionDetail from './SubscriptionDetail';
import { API_ENDPOINTS } from '../../utils/apiConfig';
import MapPopup from '../Map/MapPopup';
import RoomIcon from '@mui/icons-material/Room';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';

interface InvestmentCounts {
  [planId: number]: number | null;
}

const SubscriptionList: React.FC = () => {
  const [selectedPlanId, setSelectedPlanId] = useState<number>(1);
  const [selectedRadius, setSelectedRadius] = useState<number | null>(null);
  const [selectedPlanName, setSelectedPlanName] = useState<string | null>(null);
  const [selectedCityName, setSelectedCityName] = useState<string | null>(null); // Track selected city name
  const paymentFormRef = useRef<HTMLDivElement>(null);
  const [selectedPosition, setSelectedPosition] = useState<Position>({ lat: 51.7666636, lng: 18.083333 });
  const [mapPopupOpen, setMapPopupOpen] = useState<boolean>(false);
  const [investmentCounts, setInvestmentCounts] = useState<InvestmentCounts>({});
  const { t } = useTranslation();


  useEffect(() => {
    const fetchInvestmentCountsBatch = async () => {
      try {
        const distances = [50, 100, 150, 160];
        const url = `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.COUNT_INVESTMENTS_BATCH}?latitude=${selectedPosition.lat}&longitude=${selectedPosition.lng}${distances
          .map((d) => `&distancesInKm=${d}`)
          .join('')}`;
  
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch batch investment counts');
  
        const counts = await response.json();
  
        // Przypisujemy wyniki zgodnie z interfejsem InvestmentCounts
        const investmentCountsResult: InvestmentCounts = {
          1: counts[0], // Local Start
          2: counts[1], // Regional Growth
          3: counts[2], // Maximum Range
          4: counts[3], // Your Plan
        };
        setInvestmentCounts(investmentCountsResult);
      } catch (error) {
        console.error('Error fetching batch investment counts:', error);
        setInvestmentCounts({}); // Wyczyść dane w przypadku błędu
      }
    };
  
    fetchInvestmentCountsBatch();
  }, [selectedPosition]);
  

  const handleOpenMapPopup = () => {
    setMapPopupOpen(true);
  };

  const handleCloseMapPopup = () => {
    setMapPopupOpen(false);
  };

  const handleConfirmMapSelection = (position: Position, cityName: string | null) => {
    handleCitySelection(position, cityName); // Update selected city and position
    handleCloseMapPopup(); // Close the popup after confirming
  };

  const handlePlanSelect = (id: number, name: string, radius: number) => {
    setSelectedPlanId(id);
    setSelectedPlanName(name);
    setSelectedRadius(radius);
  };

  // const handleFetchPoints = (planId: number, position: Position, radius: number) => {
  //   // Perform the API call to fetch the number of points (buildings) based on the selected position and radius
  //   fetchInvestmentCount(planId, position.lat, position.lng, radius);
  // };


  const handleScrollToForm = () => {
    if (paymentFormRef.current) {
      paymentFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCitySelection = (position: Position, cityName: string | null) => {
    setSelectedPosition(position);
    setSelectedCityName(cityName);
  };

  const plansData = [
    {
      id: 1,
      name: `${t(Resource.Pricing.Plans.Plan1.Title)}`,
      price: `${t(Resource.Pricing.Plans.Plan1.Price)}`,
      range: `${t(Resource.Pricing.Plans.Plan1.Range)}`,
      details: ['Zasięg do 50 km', 'Standardowe funkcje', 'Podstawowe wsparcie'],
      color: colors.subscription1.active,
      inactiveColorLight: colors.subscription1.inactiveLight,
      inactiveColorDark: colors.subscription1.inactiveDark,
      isBuyable: true,
      radius: 50
    },
    {
      id: 2,
      name: `${t(Resource.Pricing.Plans.Plan2.Title)}`,
      price: `${t(Resource.Pricing.Plans.Plan2.Price)}`,
      range: `${t(Resource.Pricing.Plans.Plan2.Range)}`,
      details: ['Zasięg do 100 km', 'Zaawansowane funkcje', 'Analizy i raporty'],
      color: colors.subscription2.active,
      inactiveColorLight: colors.subscription2.inactiveLight,
      inactiveColorDark: colors.subscription2.inactiveDark,
      isBuyable: true,
      radius: 100
    },
    // {
    //   id: 3,
    //   name: `${t(Resource.Pricing.Plans.Plan3.Title)}`,
    //   price: `${t(Resource.Pricing.Plans.Plan3.Price)}`,
    //   range: `${t(Resource.Pricing.Plans.Plan3.Range)}`,
    //   details: ['Zasięg do 150 km', 'Wszystkie funkcje', 'Wsparcie premium'],
    //   color: colors.subscription3.active,
    //   inactiveColorLight: colors.subscription3.inactiveLight,
    //   inactiveColorDark: colors.subscription3.inactiveDark,
    //   isBuyable: true,
    //   radius: 150
    // },
    {
      id: 4,
      name: `${t(Resource.Pricing.Plans.Plan4.Title)}`,
      price: '??? zł',
      range: '',
      details: ['Dostosowany zasięg', 'Modułowa konstrukcja pakietu'],
      color: colors.subscription4.active,
      inactiveColorLight: colors.subscription4.inactiveLight,
      inactiveColorDark: colors.subscription4.inactiveDark,
      hasSlider: true,
      isBuyable: false,
      radius: 160
    }
  ];

  return (
    <Box sx={{ flexGrow: 1, marginY: 10 }}>
      <TestAppBottomSection />
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 3 }}>
        {t(Resource.Pricing.CitySelection.Header)}
      </Typography>
      <Typography variant="body2" sx={{ color: colors.textGray, textAlign: 'center', marginBottom: 3 }}>
        {t(Resource.Pricing.CitySelection.Description)}
      </Typography>
      <Typography variant="body1" sx={{ color: colors.warmOrange, textAlign: 'center', marginBottom: 0 }}>
        {t(Resource.Pricing.CitySelection.SelectedCity)}
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.warmOrange, textAlign: 'center', marginBottom: 3 }}>
        {selectedCityName ? selectedCityName : `${t(Resource.Pricing.CitySelection.DefaultCity)}`}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <Button
          sx={{
            borderColor: colors.warmOrange,
            color: colors.warmOrange,
            backgroundColor: 'transparent',
            borderRadius: 0,
            '&:hover': {
              borderColor: colors.warmOrange,
              bgColor: 'transparent',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'none',
          }}
          size="small"
          variant="outlined"
          onClick={handleOpenMapPopup}
          startIcon={<RoomIcon sx={{ color: colors.warmOrange }} />} // Add the icon here
        >
          {t(Resource.Buttons.ChangeCityButton)}
        </Button>
      </Box>
      <Grid container spacing={2}>
        {plansData.map(plan => (
          <Grid item xs={12} sm={4} md={4} key={plan.id}>
            <SubscriptionCard
              plan={plan}
              selected={plan.id === selectedPlanId}
              onSelect={handlePlanSelect}
              onScrollToForm={handleScrollToForm}
              coordinates={selectedPosition}
              initialCount={investmentCounts[plan.id] || 0}
              selectedCityName={selectedCityName}
              onCitySelect={handleCitySelection}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant='body1' sx={{ color: colors.textGray, textAlign: 'center', marginY: 5 }}>{t(Resource.Pricing.VatNotice)}</Typography>
      <SubscriptionDetail />

      <Typography variant="body2" sx={{ textAlign: 'center', marginTop: 3 }}>
        {t(Resource.Pricing.FaqNotice)} <a href="/faq" style={{ color: colors.warmOrange, textDecoration: 'none' }}>{t(Resource.Pricing.FaqLink)}</a>.
      </Typography>

      <div ref={paymentFormRef}>
        <PaymentFormSection selectedPlan={selectedPlanName} />
      </div>

      <MapPopup
        open={mapPopupOpen}
        onClose={handleCloseMapPopup}
        position={selectedPosition}
        onLocationSelect={handleCitySelection}
        onConfirm={handleConfirmMapSelection}
        radius={selectedRadius}
      />
    </Box>
  );
};

export default SubscriptionList;
